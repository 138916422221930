<template>
  <div class="mt-6">
    <div class="text-2xl font-semibold">III. CHẨN ĐOÁN</div>

    <div class="row mt-3">
      <div class="col-md-6 row">
        <div class="col-md-9">
          <label class="fs-14 txt-grey-900">20. Tuyến dưới</label>
          <input
            v-model="form.diagnose_lowerfacility_name"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Mã</label>
          <input
            v-model="form.diagnose_lowerfacility_code"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>

      <div class="col-md-6 row">
        <div class="col-md-9">
          <label class="fs-14 txt-grey-900">21. Cấp cứu KKB</label>
          <input
            v-model="form.diagnose_emergencydept_name"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Mã</label>
          <input
            v-model="form.diagnose_emergencydept_code"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
    </div>

    <div class="row mt-2.5">
      <div class="col-md-6 row">
        <div class="col-md-9">
          <label class="fs-14 txt-grey-900">22. Khoa điều trị</label>
          <input
            v-model="form.diagnose_treatmentdept_name"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Mã</label>
          <input
            v-model="form.diagnose_treatmentdept_code"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
      <div class="col-md-6 row">
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">T</label>
          <input
            v-model="form.t_value"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">N</label>
          <input
            v-model="form.n_value"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">M</label>
          <input
            v-model="form.m_value"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Giai đoạn</label>
          <input
            v-model="form.disease_stage"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
    </div>

    <div class="row mt-2.5">
      <div class="col-md-12 fs-14 txt-grey-900 mb-2">23. Ra viện</div>
      <div class="col-md-6 row">
        <div class="col-md-9">
          <label class="fs-14 txt-grey-900">Bệnh chính</label>
          <input
            v-model="form.disease_main_name"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Mã</label>
          <input
            v-model="form.disease_main_code"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
      <div class="col-md-6 row">
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">T</label>
          <input
            v-model="form.disease_t_value"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">N</label>
          <input
            v-model="form.disease_n_value"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">M</label>
          <input
            v-model="form.disease_m_value"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
    </div>

    <div class="mt-2.5 row">
      <div class="col-md-12 row">
        <div class="col-md-9">
          <label class="fs-14 txt-grey-900">Bệnh kèm theo</label>
          <input
            v-model="form.disease_including_name"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Mã</label>
          <input
            v-model="form.disease_including_code"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
    </div>

    <div class="mt-2.5 row">
      <div class="col-md-4">
        <div>
          <label for="nam" class="fs-14 txt-grey-900">Tình hình phẫu thuật</label>
        </div>
        <div class="flex mt-1.5">
          <label for="type7yes" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="type7yes"
              :value="1"
              class="mr-2"
              v-model="form.surgery_status"
            />Có
          </label>
          <label for="type7no" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="type7no"
              :value="2"
              class="mr-2"
              v-model="form.surgery_status"
            />Không
          </label>
          <label class="d-flex align-items-center">
            <button
              @click="form.surgery_status = null"
              class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
            >Chọn lại</button>
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div>
          <label for="nam" class="fs-14 txt-grey-900">Tai biến</label>
        </div>
        <div class="flex mt-1.5">
          <label for="type8yes" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="type8yes"
              :value="1"
              class="mr-2"
              v-model="form.is_catastrophe"
            />Có
          </label>
          <label for="type8no" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="type8no"
              :value="2"
              class="mr-2"
              v-model="form.is_catastrophe"
            />Không
          </label>
          <label class="d-flex align-items-center">
            <button
              @click="form.is_catastrophe = null"
              class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
            >Chọn lại</button>
          </label>
        </div>
      </div>
      <div class="col-md-4">
        <div>
          <label for="nam" class="fs-14 txt-grey-900">Biến chứng</label>
        </div>
        <div class="flex mt-1.5">
          <label for="type9yes" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="type9yes"
              :value="1"
              class="mr-2"
              v-model="form.is_complications"
            />Có
          </label>
          <label for="type9no" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="type9no"
              :value="2"
              class="mr-2"
              v-model="form.is_complications"
            />Không
          </label>
          <label class="d-flex align-items-center">
            <button
              @click="form.is_complications = null"
              class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
            >Chọn lại</button>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChanDoan',
  props: ['form', 'isEdit']
}
</script>
