<template>
  <div class="mt-6">
    <div class="text-2xl font-semibold">B - TỔNG KẾT BỆNH ÁN</div>
    <div class="text-lg font-semibold mt-2.5">1. Quá trình bệnh lý và diễn biến lâm sàng</div>
    <EditorInput
      :disabled="!isEdit"
      id="clinical_progress-bavv"
      :prop_text="form.clinical_progress"
      @onChangeContent="(content) => form.clinical_progress = content"
    />
    <div
      class="text-lg font-semibold mt-2.5"
    >2. Tóm tắt kết quả xét nghiệm cận lâm sàng có giá trị chẩn đoán:</div>
    <EditorInput
      :disabled="!isEdit"
      id="laboratory_test_summary-bavv"
      :prop_text="form.laboratory_test_summary"
      @onChangeContent="(content) => form.laboratory_test_summary = content"
    />

    <div class>
      <div>
        <div class="text-lg font-semibold mt-2.5">3. Phương pháp điều trị:</div>
      </div>
      <div class="flex mt-2">
        <label for="type11" class="d-flex align-items-center mr-4 fs-16 txt-grey-800">
          <input
            :disabled="!isEdit"
            type="radio"
            id="type11"
            :value="1"
            class="mr-2"
            v-model="form.tx_opt"
          />1. Điều trị
          triệt để:
        </label>
        <label for="type12" class="d-flex align-items-center mr-4 fs-16 txt-grey-800">
          <input
            type="radio"
            :disabled="!isEdit"
            id="type12"
            :value="2"
            class="mr-2"
            v-model="form.tx_opt"
          />2. Điều
          trị triệu
          chứng:
        </label>
        <label class="d-flex align-items-center mr-4">
          <button
            @click="form.tx_opt = null"
            class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
          >Chọn lại</button>
        </label>
      </div>

      <div class="row mt-2.5">
        <div class="col-md-6">
          <label class="fs-14 txt-grey-900">Tia xạ tiền phẫu</label>
          <input
            v-model="form.tx_rad_preoperative"
            :readonly="!isEdit"
            type="text"
            class="form--input"
          />
        </div>

        <div class="col-md-6">
          <label class="fs-14 txt-grey-900">Tại hạch</label>
          <input
            v-model="form.tx_rad_preoperative_ganglion"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
      <div class="row mt-2.5">
        <div class="col-md-6">
          <label class="fs-14 txt-grey-900">Tia xạ đơn thuần:</label>
          <input
            v-model="form.tx_rad_pure"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>

        <div class="col-md-6">
          <label class="fs-14 txt-grey-900">Tại hạch</label>
          <input
            v-model="form.tx_rad_pure_ganglion"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
      <div class="row mt-2.5">
        <div class="col-md-12">
          <label class="fs-14 txt-grey-900">Phẫu thuật (tên):</label>
          <input
            v-model="form.tx_surgery"
            type="text"
            :readonly="!isEdit"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
      <div class="row mt-2.5">
        <div class="col-md-6">
          <label class="fs-14 txt-grey-900">Tia xạ hậu phẫu:</label>
          <input
            v-model="form.tx_rad_postoperative"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>

        <div class="col-md-6">
          <label class="fs-14 txt-grey-900">Tại hạch</label>
          <input
            v-model="form.tx_rad_postoperative_ganglion"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
      <div class="row mt-2.5">
        <div class="col-md-12">
          <label class="fs-14 txt-grey-900">Hoá chất (phác đồ):</label>
          <input
            v-model="form.tx_chemicals"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-2 flex justify-center items-center">
          <label class="fs-14 txt-grey-900 w-20">Số đợt</label>
          <input
            v-model="form.batch_number"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>

      <div class="mt-2.5 row">
        <div class="col-md-12">
          <label for="nam" class="fs-16 txt-grey-900">Đáp ứng</label>
          <div class="flex mt-2 items-center">
            <label for="type2no" class="d-flex align-items-center mr-4 fs-16 txt-grey-800">
              <input
                :disabled="!isEdit"
                type="radio"
                id="type2no"
                :value="1"
                class="mr-2"
                v-model="form.tx_meet"
              />1.
              Không đáp ứng
            </label>
            <label for="tx_meetpart" class="d-flex align-items-center mr-4 fs-16 txt-grey-800">
              <input
                :disabled="!isEdit"
                type="radio"
                id="tx_meetpart"
                :value="2"
                class="mr-2"
                v-model="form.tx_meet"
              />2.
              Bán phần
            </label>
            <label for="tx_meetyes" class="d-flex align-items-center mr-4 fs-16 txt-grey-800">
              <input
                :disabled="!isEdit"
                type="radio"
                id="tx_meetyes"
                :value="3"
                class="mr-2"
                v-model="form.tx_meet"
              />
              3. Hoàn toàn
            </label>
            <label class="d-flex align-items-center mr-4">
              <button
                @click="form.tx_meet = null"
                class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
              >Chọn lại</button>
            </label>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 flex justify-center items-center">
          <label class="fs-14 txt-grey-900 w-28">Điều trị khác:</label>
          <input
            v-model="form.tx_other"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>

      <div class="mt-3">
        <label class="fs-18 fw-700 txt-grey-900">4. Tình trạng người bệnh ra viện</label>
        <EditorInput
          :disabled="!isEdit"
          id="discharge_status-bavv"
          :prop_text="form.discharge_status"
          @onChangeContent="(content) => form.discharge_status = content"
        />
      </div>

      <div class="mt-3">
        <label class="fs-18 fw-700 txt-grey-900">5. Hướng điều trị và các chế độ tiếp theo</label>
        <EditorInput
          :disabled="!isEdit"
          id="treatment_direction_next-bavv"
          :prop_text="form.treatment_direction_next"
          @onChangeContent="(content) => form.treatment_direction_next = content"
        />
      </div>
    </div>

    <div class="my-2.5">
      <div class="text-lg font-semibold mt-2.5">Hồ sơ, phim, ảnh</div>

      <div class="mt-2.5 row">
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-6 fs-14 txt-grey-900">Loại</div>
            <div class="col-md-3 fs-14 txt-grey-900">Số tờ</div>
          </div>
          <div class="row mt-2.5" v-for="(file, index) in filesChild" :key="index">
            <div class="col-md-6 fs-14 txt-grey-900">{{ file.type }}</div>
            <div class="col-md-3 fs-14 txt-grey-900">
              <input
                v-model="file.count"
                @change="uploadDocs"
                type="text"
                :readonly="!isEdit"
                class="form--input txt-grey-900"
              />
            </div>
          </div>
        </div>

        <div class="col-md-8">
          <div class="row">
            <div class="col-md-9 fs-14 txt-grey-900">Người giao hồ sơ</div>
            <div class="col-md-3 fs-14 txt-grey-900">Ngày tháng</div>
          </div>
          <div class="row mt-2.5">
            <div class="col-md-9 fs-14 txt-grey-900 text-right">
              <input
                :readonly="!isEdit"
                v-model="form.profile_deliver"
                type="text"
                class="form--input txt-grey-900"
              />
            </div>
            <div class="col-md-3 fs-14 txt-grey-900">
              <input
                v-model="form.profile_delivered_at"
                :readonly="!isEdit"
                type="date"
                class="form--input txt-grey-900"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-9 fs-14 txt-grey-900">Người nhận hồ sơ:</div>
            <div class="col-md-3 fs-14 txt-grey-900">Ngày tháng</div>
          </div>
          <div class="row mt-2.5">
            <div class="col-md-9 fs-14 txt-grey-900 text-right">
              <input
                :readonly="!isEdit"
                v-model="form.profile_recipient"
                type="text"
                class="form--input txt-grey-900"
              />
            </div>
            <div class="col-md-3 fs-14 txt-grey-900">
              <input
                :readonly="!isEdit"
                v-model="form.profile_received_at"
                type="date"
                class="form--input txt-grey-900"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditorInput from '@/components/EditorInput.vue'

export default {
  name: 'BTongKetBenhAn',
  props: ['form', 'medical_record_files', 'isEdit'],
  components: { EditorInput },
  data () {
    return {
      filesChild: []
    }
  },
  mounted () {
    if (this.medical_record_files && this.medical_record_files.length > 0) {
      this.filesChild = this.medical_record_files
    } else {
      this.filesChild = [
        {
          type: 'X - quang',
          count: ''
        },
        {
          type: 'CT Scanner',
          count: ''
        },
        {
          type: 'Siêu âm',
          count: ''
        },
        {
          type: 'Xét nghiệm',
          count: ''
        },
        {
          type: 'Khác',
          count: ''
        }
      ]
    }
  },
  watch: {
    medical_record_files (value) {
      if (value) {
        this.filesChild = value
      }
    }
  },
  methods: {
    uploadDocs () {
      this.$emit('uploadDocs', this.filesChild)
    }
  }
}
</script>

